import { ConditionalExpr } from '@angular/compiler';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ProcessToken } from 'src/app/core/models/process-token';
import { EsignService } from 'src/app/core/services/esign.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  title: string;
  message: string;
  buttonText: { ok: string, cancel: string };
  otpCode: string;
  showOtpComponent = true;
  correctCode = false;
  close = false;
  phoneNumber: string;
  processToken = new ProcessToken();
  subPhoneNumber: string;
  otp = false;
  otpForm = FormGroup;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };

  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel, private esignService: EsignService, private snackbarService: SnackbarService) {

    if (data) {
      this.title = data.title;
      this.message = data.message;
      this.buttonText = data.buttonText;
      this.otpCode = data.otpCode;
      this.processToken = data.processToken;
      var phoneNumberlength = this.processToken.mobilePhoneNumber.length;
      this.subPhoneNumber = '+XXX...' + this.processToken.mobilePhoneNumber.substring(phoneNumberlength - 3);


    }

  }

  onOtpChange(otpCode) {
    if (isNotNullOrUndefined(otpCode) && otpCode === this.otpCode) {
      this.close = true;
      this.otp = false;

    }
    else if (isNotNullOrUndefined(otpCode) && otpCode.length === 6) {

      this.otp = true;
    }

  }


  ngOnInit(): void {

  }
  reSendCode(): void {
    this.esignService.SendSMS(this.processToken.processId, this.processToken.signatoryId)
      .subscribe(
        res => {
          this.snackbarService.showNotification('Un nouveau code SMS a été envoyé sur votre téléphone ', 'X', ["success-style"]);
          this.otpCode = res.body.smsCode;

        }, er => {
          this.snackbarService.showNotification(er.error.message.additionalInfo[0].message, "X", ["custom-style"])
        });
  }

  onConfirm(): void {

    this.close = true;
    this.dialogRef.close(true);

  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(public title: string, public message: string, public otpCode: string, public processToken: ProcessToken, public buttonText: { ok: string, cancel: string }) {
  }

}
