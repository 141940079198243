
<mat-toolbar class="profileNavBar" color="secondary">
  <div class="profile">

    <img class="profile-img" src="..\assets\profileImage\profile-user.svg">
    <div class="profile-info">
      <p>{{signatoryName}}</p>
      <p>{{signatoryEmail}}</p>

    </div>
  </div>
</mat-toolbar>
<div class="toolbar">
  <div class="consent">
    <p class="record" *ngIf="timeOut && !action && !hideContinue">Veuillez lire <a>l'enregistrement électronique et la divulgation de la signature</a></p>
    <mat-checkbox *ngIf="activeButtons && timeOut && !action" [(ngModel)]="checked" class="example-margin"> J'accepte d'utiliser des enregistrements et des signatures électroniques
    </mat-checkbox>
  </div>
  <div class="spacer">
  </div>
  <div>
  <button (click)="enableSign(activeButtons)" *ngIf=" timeOut && !action && !hideContinue" [disabled]="!checked" class="continue-btn esign-btn"
    >CONTINUER
  </button>
  </div>
</div>
<div class="content" role="main">
  <app-esign-pdf (profilInformation)="receiveprofilInformation($event)"
    [activeSignRefuse]="activeButtons" (timeOut)= "receiveTimeOut($event)"  (signRefuse)='receiveSignRefuse($event)'>
  </app-esign-pdf>
</div>
