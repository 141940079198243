import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter, Inject } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { saveAs } from 'file-saver';
import { ConfirmDialogModel, ConfirmPopupComponent } from '../shared/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { FileMngtService } from '../core/services/file-mngt.service';
import { EsignService } from '../core/services/esign.service';
import { Signatorydecisiondetail } from '../core/models/signatorydecisiondetail';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ProcessToken } from '../core/models/process-token';
import { SnackbarService } from '../shared/services/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';



@Component({
  selector: 'app-esign-pdf',
  templateUrl: './esign-pdf.component.html',
  styleUrls: ['./esign-pdf.component.scss']
})
export class EsignPdfComponent implements OnInit {
  disable = true;
  pdfSrc: any;
  rotation = 0;
  zoom = 1;
  zoomScale: 'page-width' | 'page-fit' | 'page-height' = 'page-width';
  pdfQuery = '';
  totalPages = 0;
  pdfDocument: Blob;
  byteArray: any;
  timeStamp = new Date();
  processId: string;
  signatoryId: string;
  documentId: string;
  processToken = new ProcessToken();
  processTokenString: string;
  isValid = false;
  finishProcess = false;
  finishRefusal = false;
  ConfirmSign = false;
  generatedSmsCode: string;
  @ViewChild('pdfDoc', { static: false }) pdfDoc: ElementRef;
  @Input() activeSignRefuse: boolean;
  @Output() timeOut = new EventEmitter<boolean>();
  @Output() profilInformation = new EventEmitter<ProcessToken>();
  @Output() signRefuse = new EventEmitter<boolean>();
  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;

  constructor(private fileMngtService: FileMngtService,
    private dialog: MatDialog,
    private esignService: EsignService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router, private snackbarService: SnackbarService) {

  }

  ngOnInit(): void {
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        const routeParams = val.state.root.firstChild?.params;

        this.processTokenString = routeParams['processToken'];

        var decodedprocessToken = JSON.parse(atob(this.processTokenString));        
        this.processToken.processId = decodedprocessToken.process.id;
        this.processToken.processValidUntil = decodedprocessToken.process.validUntil;
        this.processToken.currentDate = decodedprocessToken.process.currentDate;
        this.processToken.processCurrentStatus = decodedprocessToken.process.status;

        this.processToken.signatoryId = decodedprocessToken.signatory.signatoryId;
        this.processToken.signatoryName = decodedprocessToken.signatory.name;
        this.processToken.signatoryEmail = decodedprocessToken.signatory.email;
        this.processToken.mobilePhoneNumber=decodedprocessToken.signatory.mobilePhoneNumber;                 
        this.processToken.signatoryCurrentStatus = decodedprocessToken.signatory.status;

        this.processToken.documentId = decodedprocessToken.document.id;
        this.processToken.documentOriginalFileName = decodedprocessToken.document.originalFileName;

        this.profilInformation.emit(this.processToken);
        if (isNotNullOrUndefined(this.processToken.documentId)) {
          this.fileMngtService.getById(this.processToken.documentId).subscribe(
            (data: any) => {
              this.byteArray = new Uint8Array(data);
              this.pdfDocument = new Blob([this.byteArray], { type: 'application/pdf' });
            }, er => {
              if (er.status === 404) {
                this.snackbarService.showNotification("Le document n'a pas été trouvé", "X",["custom-style"]);

              }
            }

          );
        }
        if (new Date(this.processToken.processValidUntil) > new Date(this.processToken.currentDate)) {
          this.isValid = true;
        }
      }
    }
    );
  }


  zoomIn(): void {
    this.zoom += 0.05;
  }

  zoomOut(): void {
    if (this.zoom > 0.05) {
      this.zoom -= 0.05;
    }
  }

  callBackFn(event: any): void {
    this.totalPages = event._pdfInfo.numPages;
  }

  download(): void {
    const blob = this.pdfDocument;
    saveAs(blob, this.processToken.documentOriginalFileName);
  }

  sign(): void {
    this.esignService.SendSMS(this.processToken.processId, this.processToken.signatoryId)
      .subscribe(
        res => {         
          this.generatedSmsCode = res.body.smsCode
          this.ConfirmSign = true;          
          this.openDialog();


        }, er => {
          this.handleError(er);
        });

  }
  notifTimeOut() {
    this.timeOut.emit(this.isValid);
  }
  handleError(err: Object) {
    if (err instanceof HttpErrorResponse) {

      this.snackbarService.showNotification(err.error.message.additionalInfo[0].message, "X",["custom-style"]);

    }

  }

  openDialog(): void {
    var phoneNumberlength= this.processToken.mobilePhoneNumber.length;
    const dialogData = new ConfirmDialogModel('Confirm Action', 'Pour confirmer la signature. Veuillez saisir le code SMS reçu sur votre téléphone ', this.generatedSmsCode,this.processToken,
      { ok: 'Ok', cancel: 'Annuler' }
    );
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '500px',
      disableClose: false,
      data: dialogData
    }).afterClosed().subscribe(res => {
      if (res === true) {        
        const signatorydecisiondetail = new Signatorydecisiondetail(this.datePipe.transform(this.timeStamp, 'yyyy-MM-dd hh:mm:ss'));
        if (this.processToken.processCurrentStatus === 'CHECKING') {
          this.esignService.registerConsent(this.processToken.processId, this.processToken.signatoryId, signatorydecisiondetail).subscribe(
            data => {
              
              this.finishProcess = true;
              this.signRefuse.emit(true);
              this.snackbarService.showNotification(' Le processus a été signé avec succès', 'X',["success-style"]);


            }, er => {

              if (er.error.message.additionalInfo[0].message === 'The process status is invalid') {
                this.snackbarService.showNotification(er.error.message.additionalInfo[0].message + ' au signataire actuel', "X",["custom-style"])
              }
              else {
                this.handleError(er);
              }

            }

          );
        }
      }
    });
  }

  registerRefusal(): void {

    const signatorydecisiondetail = new Signatorydecisiondetail(this.datePipe.transform(this.timeStamp, 'yyyy-MM-dd hh:mm:ss'));
    if (this.processToken.processCurrentStatus === 'CHECKING') {
      this.esignService.registerRefusal(this.processToken.processId, this.processToken.signatoryId, signatorydecisiondetail)
        .subscribe(
          res => {
            this.finishRefusal = true;
            this.signRefuse.emit(true);


          }, er => {
            this.handleError(er);
          });
    }
  }


}
