<mat-dialog-content>
  <h3>{{message}} <a class='phoneNumber'> {{subPhoneNumber}}</a></h3>

 
 <div class="ng-otp-input">
  <ng-otp-input  #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config"></ng-otp-input>
  </div>
<span *ngIf="otp" class="o-t-p"> Incorrect code, Please enter a correct code</span>

</mat-dialog-content>
<mat-dialog-actions>
  <p><a class="resendCode" href="" onclick="return false;"(click)="reSendCode()">Renvoyer le code</a></p>
  <div class="endButton">
  <button mat-raised-button class="esign-btn" mat-dialog-close (click)="onDismiss()"  tabindex="-1">{{buttonText?.cancel}}</button>
  <button mat-raised-button class="esign-btn"  mat-dialog-close [disabled]="!close" (click)="onConfirm()" tabindex="-1">{{buttonText?.ok}}</button>
</div>
</mat-dialog-actions>
