<div *ngIf="isValid, else hideDoc">
  <div *ngIf="!finishProcess && !finishRefusal ,else elseBloc"  class="pdf-viewer-wrap">

    <div class="pdf-viewer-controls-wrap">
      <div class="pdf-viewer-controls">
        <div class="pdfv-control">
          <button (click)="zoomOut()" aria-label="zoom_out" mat-icon-button>
            <mat-icon>zoom_out</mat-icon>
          </button>
        </div>
        <div class="pdfv-control">
          <button (click)="zoomIn()" aria-label="zoom_in" mat-icon-button>
            <mat-icon>zoom_in</mat-icon>
          </button>
        </div>
        <div class="pdfv-control">
          <button (click)="download()" aria-label="save" mat-icon-button>
            <mat-icon>save_alt</mat-icon>
          </button>
        </div>
      </div>
      <div #pdfDoc class="pdf-viewer-viewer-window">
        <pdf-viewer (after-load-complete)="callBackFn($event)" [autoresize]="true" [fit-to-page]="false"
          [original-size]="false" [render-text-mode]="1" [render-text]="false" [rotation]="rotation" [show-all]="true"
          [show-borders]="true" [src]="byteArray" [zoom-scale]="zoomScale" [zoom]="zoom">
        </pdf-viewer>
      </div>
      <div class="pdf-viewer-btns">
        <button (click)="registerRefusal()" [disabled]="activeSignRefuse" class="m-20 esign-btn"
          mat-raised-button>Refuser</button>
        <button (click)="sign()" [disabled]="activeSignRefuse" class="m-20 esign-btn" mat-raised-button>Signer</button>
      </div>
    </div>
  </div>

  <ng-template #elseBloc>
    <div class="center-v" fxLayout="column" fxLayoutAlign="center center">
      <!--<h1> Vous avez terminé!</h1>-->
      <h1><p *ngIf="!finishRefusal">  La signature a été effectuée avec succès </p></h1>
      <h1> <p *ngIf="finishRefusal"> Votre refus de signature a été envoyé à tous les signataires </p></h1>
    </div>
  </ng-template>

</div>
<ng-template #hideDoc>
  <div class="center-v" fxLayout="column" fxLayoutAlign="center center">
    <p> Le processus de signature a expiré</p>
  </div>
</ng-template>
