import { Component, OnInit } from '@angular/core';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ActivatedRoute } from '@angular/router';
import { ProcessToken } from './core/models/process-token';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  checked = false;
  activeButtons = true;
  signatoryName: string;
  signatoryEmail: string;
  timeOut = true;
  action = false;
  hideContinue = false;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

  }

  enableSign(value: boolean): void {
    this.activeButtons = !value;
    this.hideContinue = true;


  }
  receiveSignRefuse(action: boolean) {
    this.action = action;
  }


  receiveprofilInformation(processToken: ProcessToken) {
    if (isNotNullOrUndefined(processToken)) {
      this.signatoryEmail = processToken.signatoryEmail;
      this.signatoryName = processToken.signatoryName;

    }
  }
  receiveTimeOut(isValid: boolean) {
    this.timeOut = isValid;
  }
}
