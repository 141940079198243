export class SignatoryDecisionLocation {
  ip: string;
  city: string;
  region: string;
  country: string;


  constructor(ip: string, city: string, region: string, country: string) {
    this.ip = ip;
    this.city = city;
    this.region = region;
    this.country = country;
  }
}
