export class ProcessToken {

    processId?: string;
    processValidUntil?: string;
    currentDate?: string;
    processCurrentStatus?: string;

    signatoryId?: string
    signatoryName?: string;
    signatoryEmail?: string;
    mobilePhoneNumber?:string;
    signatoryCurrentStatus?: string;

    documentId?: string;
    documentOriginalFileName?: string;

    constructor(processId?: string,
                processValidUntil?: string,
                currentDate?: string,
                processCurrentStatus?: string,

                signatoryId?: string,
                signatoryName?: string,
                signatoryEmail?: string,
                mobilePhoneNumber?: string,
                signatoryCurrentStatus?: string,

                documentId?: string,
                documentOriginalFileName?: string) {
        this.processId = processId;
        this.processValidUntil = processValidUntil;
        this.currentDate = currentDate;
        this.processCurrentStatus = processCurrentStatus;

        this.signatoryId = signatoryId;
        this.signatoryName = signatoryName;
        this.signatoryEmail = signatoryEmail;
        this.mobilePhoneNumber= mobilePhoneNumber;
        this.signatoryCurrentStatus = signatoryCurrentStatus;

        this.documentId = documentId;
        this.documentOriginalFileName = documentOriginalFileName;
    }


}
