export class Signatorydecisiondetail {
  objectType?: string;
  systemUid?: string;
  clientTimestamp: string;
  resourceUid?: string;
  comment?: string;


  constructor(clientTimestamp: string, objectType?: string, systemUid?: string, resourceUid?: string, comment?: string) {
    this.objectType = objectType;
    this.systemUid = systemUid;
    this.clientTimestamp = clientTimestamp;
    this.resourceUid = resourceUid;
    this.comment = comment;
  }
}
