import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {header} from '../constants/app-constants';
import {EnvService} from './env.service';

@Injectable({
  providedIn: 'root'
})
export class FileMngtService {

  constructor(private http: HttpClient, private env: EnvService) {
}

  public getById(id: string): Observable<any> {
    return this.http.get(this.env.fileMngt + '/' + id + '/download/', {headers: header, responseType: 'arraybuffer'});
  }
}
