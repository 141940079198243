import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  showNotification(message,actionType, panelClassInfo) {
    
    let sb = this.snackBar.open(message, actionType, {
      duration: 4000, horizontalPosition: "right",
      panelClass: panelClassInfo
    });
    sb.onAction().subscribe(() => {
      sb.dismiss();
    });
  }
}
