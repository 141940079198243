import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {SignatoryDecisionLocation} from "../models/signatorydecisionlocation";

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getIpAddress() {
    return this.http
      .get('https://api.ipify.org/?format=json')
      .pipe( map( (res: any) => res.ip ) );
  }

  getGEOLocation(ip) {
    const url = 'https://ipinfo.io/' + ip + '/json?token=a7e7c2b50aa176';
    return this.http
      .get(url)
      .pipe( map( (res: any) => new SignatoryDecisionLocation(ip, res.city, res.region, res.country) ) );
  }

}
