import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EsignPdfComponent} from './esign-pdf/esign-pdf.component';

const routes: Routes = [
  {path: ':processToken', component: EsignPdfComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
