import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { EsignPdfComponent } from './esign-pdf/esign-pdf.component';
import {SharedModule} from './shared/shared.module';
import {HttpClientModule } from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DatePipe} from '@angular/common';
import {EnvServiceProvider} from './core/services/env.service.provider';

@NgModule({
  declarations: [
    AppComponent,
    EsignPdfComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    FlexLayoutModule,
    ReactiveFormsModule
    ],

  providers: [DatePipe, EnvServiceProvider],
  bootstrap: [AppComponent]
})
export class AppModule { }
