import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatToolbarModule} from '@angular/material/toolbar';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { NgOtpInputModule } from  'ng-otp-input';



@NgModule({
  declarations: [ConfirmPopupComponent],
    imports: [
        CommonModule,
        MatIconModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatMenuModule,
        MatCheckboxModule,
        PdfViewerModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatDialogModule,
        MatListModule,
        MatMenuModule,
        MatSnackBarModule,
        MatInputModule,
        NgOtpInputModule


    ],
  exports: [
    MatIconModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    PdfViewerModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    NgOtpInputModule


  ]
})
export class SharedModule { }
