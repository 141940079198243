import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {header} from '../constants/app-constants';
import {Signatorydecisiondetail} from '../models/signatorydecisiondetail';
import {EnvService} from './env.service';
import {LocationService} from './location.service';

@Injectable({
  providedIn: 'root'
})
export class EsignService {

  constructor(private http: HttpClient,
              private locationService: LocationService,
              private env: EnvService) {
  }

  public registerConsent(processId: string, signatoryId: string, signatorydecisiondetail: Signatorydecisiondetail): Observable<any> {
    return new Observable<any>(subscriber => {
      this.fillLocation(signatorydecisiondetail).then(detail => {
        this.http.post(this.env.odmEsign + '/' + processId + '/signatory/' + signatoryId + '/consent/', detail, {
          headers: header,
          observe: 'response'
        }).subscribe(res =>  subscriber.next(res), error => subscriber.error(error));
      })
    });
  }

  public registerRefusal(processId: string, signatoryId: string, signatorydecisiondetail: Signatorydecisiondetail): Observable<any> {
    return new Observable<any>(subscriber => {
      this.fillLocation(signatorydecisiondetail).then(detail => {
        this.http.post(this.env.odmEsign + '/' + processId + '/signatory/' + signatoryId + '/refusal/', detail, {
          headers: header,
          observe: 'response'
        }).subscribe(res =>  subscriber.next(res), error => subscriber.error(error));
      })
    });
  }

  public SendSMS(processId: string, signatoryId: string): Observable<any> {
    return this.http.post(this.env.odmEsign + '/' + processId + '/signatory/' + signatoryId + '/smsCode/', null, {
      headers: header,
      observe: 'response'
    });
  }

  private async fillLocation(detail: Signatorydecisiondetail) {
    const ip = await this.locationService.getIpAddress().toPromise();
    const location = await this.locationService.getGEOLocation(ip).toPromise();
    return {...detail, ...location};
  }

}
